import React, { useEffect, useState } from "react"

const LlmDevCards = () => {
  const [is768px, setIs768px] = useState(false)

  useEffect(() => {
    const checkHeight = () => {
      setIs768px(window.innerHeight === 768)
    }

    // Check on initial load
    checkHeight()

    // Add listener for window resize
    window.addEventListener("resize", checkHeight)

    // Cleanup
    return () => window.removeEventListener("resize", checkHeight)
  }, [])
  const featureCards = [
    {
      title: "Custom LLM Development Tailored to Your Needs",
      description:
        "Every business is unique and so are its challenges. We specialize in developing Custom LLMs designed to address specific use cases. Whether it's automating workflows, enhancing customer service, or extracting actionable insights from data, our tailored solutions fit seamlessly into your operations.",
      bulletTitle: "Why Custom LLMs?",
      bulletPoints: [
        {
          label: "Personalization:",
          text: "Aligns with your business goals and data.",
        },
        { label: "Scalability:", text: "Grows as your business expands." },
        { label: "Efficiency:", text: "Solves unique challenges effectively." },
      ],
    },
    {
      title: "Seamless Integration with Your Systems",
      description:
        "Developing a powerful LLM is only part of the process. Ensuring it integrates smoothly with your existing infrastructure is equally critical. Our team ensures that the LLMs we develop are fully compatible with your current tech stack, delivering a unified experience without disrupting your workflows.",
      bulletTitle: "Why Integration Matters?",
      bulletPoints: [
        { text: "Smooth integration reduces operational disruptions." },
        { text: "Streamlines workflows across teams." },
        { text: "Ensures long-term scalability with your systems." },
      ],
    },
    {
      title: "Advanced Data Training",
      description:
        "The performance of an LLM depends heavily on the quality of its training data. We focus on building models trained with relevant, high-quality datasets, ensuring accurate and reliable outcomes.",
      bulletTitle: "What We Offer",
      bulletPoints: [
        { text: "Curated datasets tailored to your industry." },
        { text: "Robust algorithms for effective learning." },
        { text: "Regular updates to keep your model relevant" },
      ],
    },
  ]

  return (
    <div className="w-full  py-8">
      <div className="max-w-7xl mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {featureCards.map((card, index) => (
            <div
              key={index}
              className="bg-white rounded-lg shadow-md p-6 flex flex-col"
            >
              <h3 className="text-lg font-bold text-gray-800 mb-4 h-12">
                {card.title}
              </h3>

              <p
                className={`text-sm text-gray-600 mb-6 ${
                  is768px ? "h-40" : "h-36"
                }`}
              >
                {card.description}
              </p>

              {card.bulletPoints.length > 0 && (
                <div className="">
                  {card.bulletTitle && (
                    <h4 className="font-medium text-gray-800 mb-2">
                      {card.bulletTitle}
                    </h4>
                  )}
                  <ul className="space-y-2">
                    {card.bulletPoints.map((point, idx) => (
                      <li key={idx} className="">
                        <div className="">
                          <span className="text-gray-800 mr-1">•</span>
                          {point.label ? (
                            <span className="font-semibold text-gray-800 mr-1">
                              {point.label}
                            </span>
                          ) : null}
                          <span className="text-gray-600">{point.text}</span>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default LlmDevCards
